<template>
  <div class="page-footer">
    <div class="centered-content">
      <div class="footer-wrapper">
        <div class="address-column">
          <a style="text-decoration:none; color: #fff;" href="https://www.digitalpa.it/">
            <img src="@/assets/img/logo-digitalpa-200x.png" alt="logo-digitalpa" width="200" style="padding-top: 20px" />
            <h2>DigitalPA S.r.l.</h2>
          </a>
          <div>Via S. Tommaso d'Aquino, 18A - 09134 Cagliari</div>
          <div><strong>Altre sedi:</strong> Sulmona (Abruzzo) | Milano (Lombardia) | Roma (Lazio) | Barcellona (Spagna)</div>

          <div style="font-weight: bold"><a href="https://www.digitalpa.it"
                                            style="color: #fff; text-decoration:none">www.digitalpa.it</a></div>
        </div>

        <div style="color: #fff; padding-top: 20px;">
          <img src="@/assets/img/tuv-9001-27001-iso.png" alt="logo-digitalpa" width="50px" style="padding: 20px 0" />
          <div>Sistema di gestione certificato per la qualità e sicurezza <br> ISO 9001 | ISO IEC 27001</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterDigitalpa"
}
</script>

<style scoped>

</style>