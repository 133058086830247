import Vue from "vue";
import asmCrypto from "asmcrypto-lite";

export default {
	data: () => ({
		files: [],
		sha: [],
	}),
	methods: {
		uploadFile(e) {
			this.files = e.target.files
			for(let i = 0; i < this.files.length; i++) {
				this.generateSha(this.files[i], i)
			}
		},
		dragFile(e) {
			this.files = e.dataTransfer.files
			for(let i = 0; i < this.files.length; i++) {
				this.generateSha(this.files[i], i)
			}
		},
		handleClick() {
			this.$refs.uploader.click()
		},
		generateSha(file, index) {
			new Blob([file]).arrayBuffer().then((res) => {
				Vue.set(this.sha, index, asmCrypto.SHA256.hex(new Uint8Array(res)))
			})
		},
	}
}