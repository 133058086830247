<template>
  <div class="page-header">
    <div class="centered-content">
      <div class="header-wrapper">
        <div class="logo-section">
          <div class="logo-container">
            <a href="https://www.digitalpa.it" target="_blank"><img class="logo"
                                                                    src="https://www.digitalpa.it/media/images/logo-digitalpa-bianco-rosso360x.png"
                                                                    alt="logo DigitalPA" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderDigitalpa"
}
</script>

<style scoped>

</style>