<template>
  <div class="ml-3 mr-3">
    <textarea class="form-control" v-model="values" :placeholder="$t('lbl_inserisci_codici_hash')" rows="5"></textarea>
    <div class="text-left"><small>{{$t('textarea_hint')}}</small></div>

    <div class="custom_drag_drop_container m-3 border-primary bg-gray" @dragover.prevent @drop.prevent>
      <div @drop="dragFile" class="custom_drop_area" @click="handleClick">
        <input ref="uploader" hidden type="file" multiple @change="uploadFile"/>
      </div>
      <h2 class="text-center">{{ $t('lbl_drag_and_drop_area') }}</h2>
    </div>

    <div v-if="files.length">

      <errors-alert v-if="checked" :sha-values="values" :numFiles="files.length" :errors="errors" :verified="verified"></errors-alert>

      <div v-for="(file,index) in files" :key="index" class="border p-2">
        <div class="text-left">
          <template v-if="errors[index]">
            <div class="alert alert-danger text-left float-right w-30" role="alert">
              {{ $t('lbl_element_error') }}
            </div>
          </template>
          <template v-else-if="verified[index]">
            <div class="alert alert-success text-left float-right w-30" role="alert">
              {{ $t('lbl_element_ok') }}
            </div>
          </template>
          <div><strong>{{ $t('name') }}: </strong> {{ file.name }}</div>
          <div><strong>{{ $t('size') }}: </strong> {{ Math.floor(file.size / 1000) }} KB</div>
          <div><strong>{{ $t('last_modified') }}: </strong> {{ new Date(file.lastModified) }}</div>
          <div><strong>{{ $t('hash') }}: </strong> {{ sha[index] }}</div>
        </div>
      </div>

      <div class="text-left">
        <button class="mt-3 btn btn-primary" @click="handleHashCheck">{{$t('check')}}</button>
      </div>
    </div>

  </div>
</template>

<script>
import uploaderMixin from "@/mixins/uploaderMixin";
import Vue from "vue";
import ErrorsAlert from "@/components/ErrorsAlert";

export default {
  name: "VerificaHash",
  mixins: [uploaderMixin],
  components: {
    ErrorsAlert
  },
  data: () => ({
    values: "",
    errors: [],
    verified: [],
    checked: false
  }),
  methods: {
    handleHashCheck() {
      this.checked = true
      this.errors = []
      this.verified = []
      let hash = this.values.split(',')
      hash.forEach((el, index) => {
        if(el !== this.sha[index]) {
          Vue.set(this.errors, index, true)
        }else {
          Vue.set(this.verified, index, true)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>