<template>
  <div>
    <template v-if="!(shaValues.length > 0)">
      <div class="alert alert-warning text-left" role="alert">
        {{ $t('lbl_inserisci_hash') }}
      </div>
    </template>
    <template v-else-if="errors.filter((el) => el).length > 0">
      <div class="alert alert-danger text-left" role="alert">
        {{ $t('lbl_errori') }}
      </div>
    </template>
    <template v-else-if="shaValues.split(',').length === verified.length && verified.length === numFiles">
      <div class="alert alert-success text-left" role="alert">
        {{ $t('lbl_check_success') }}
      </div>
    </template>
    <template v-if="shaValues.split(',').length < numFiles">
      <div class="alert alert-warning text-left" role="alert">
        {{ $t('lbl_missing_sha') }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ErrorsAlert",
  props: {
    shaValues: {default: () => ""},
    errors: {default: () => []},
    numFiles: {default: () => 0},
    verified: {default: () => []}
  }
}
</script>

<style scoped>

</style>