<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
          integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">
    <header-digitalpa v-if="dataFull"></header-digitalpa>

    <ul class="nav justify-content-center nav-pills nav-tabs mb-5 mt-2">
      <li class="nav-item">
        <a class="nav-link" :class="{active: tabIndex === 0}" href="#" @click="tabIndex = 0">{{ $t('lbl_calcola') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: tabIndex === 1}" href="#" @click="tabIndex = 1">{{ $t('lbl_verifica') }}</a>
      </li>
    </ul>


    <template v-if="tabIndex===0">
      <calcolo-hash></calcolo-hash>
    </template>
    <template v-else>
      <verifica-hash></verifica-hash>
    </template>
    <footer-notes class="mt-5 ml-3 mr-3 mb-3"></footer-notes>
    <footer-digitalpa v-if="dataFull"></footer-digitalpa>
  </div>
</template>

<script>
import CalcoloHash from "@/components/CalcoloHash";
import VerificaHash from "@/components/VerificaHash";
import footerNotes from "@/components/FooterNotes";
import footerDigitalpa from "@/components/FooterDigitalpa";
import headerDigitalpa from "@/components/HeaderDigitalpa";
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from "@/locale/i18n";

Vue.use(VueI18n)

const i18n = new VueI18n({locale: 'it', messages: messages})


export default {
  i18n,
  name: 'App',
  data: () => ({
    tabIndex: 0
  }),
  components: {
    CalcoloHash,
    VerificaHash,
    footerNotes,
    footerDigitalpa,
    headerDigitalpa
  },
  props: {
    dataFull: {default: () => false}
  }
}
</script>

<style>
@import "assets/css/digitalpaCustomStyle.css";

#app {

}
</style>
