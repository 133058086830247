<template>
  <div class="border p-2">
    <h3 class="text-center">{{$t('lbl_nota_title')}}</h3>
    <p class="text-left">{{$t('lbl_nota')}}</p>
    <h4 class="text-left">{{$t('lbl_nota_2_tile')}}</h4>
    <p class="text-left">{{$t('lbl_nota_2')}}</p>
  </div>
</template>

<script>
export default {
  name: "FooterNotes"
}
</script>

<style scoped>

</style>