<template>
  <div class="ml-3 mr-3">
    <div class="custom_drag_drop_container m-3 border-primary" @dragover.prevent @drop.prevent>
      <div @drop="dragFile" class="custom_drop_area" @click="handleClick">
        <input ref="uploader" hidden type="file" multiple @change="uploadFile"/>
      </div>
      <h2 class="text-center">{{ $t('lbl_drag_and_drop_area') }}</h2>
    </div>
    <div v-if="files.length">
      <div class="alert alert-success text-left" role="alert">
        {{ $t('lbl_elaborazione_terminata') }}
      </div>
      <div v-for="(file,index) in files" :key="index" class="border p-2">
        <div class="text-left">
          <div><strong>{{ $t('name') }}: </strong> {{ file.name }}</div>
          <div><strong>{{ $t('size') }}: </strong> {{ Math.floor(file.size / 1000) }} KB</div>
          <div><strong>{{ $t('last_modified') }}: </strong> {{ new Date(file.lastModified) }}</div>
          <div><strong>{{ $t('hash') }}: </strong> {{ sha[index] }}</div>
        </div>
      </div>

      <div class="text-left mt-3">
        <button class="btn btn-success ml-3 mr-3" @click="copyToClipboard">{{$t('copy')}}</button>
        <button class="btn btn-primary" @click="reset">{{$t('reset')}}</button>
      </div>

      <div class="bg-dark mt-3 p-2" v-if="showToast"><span class="text-white">{{$t('copied')}}</span></div>

    </div>

  </div>

</template>

<script>

import uploaderMixin from "@/mixins/uploaderMixin";

export default {
  name: "CalcoloHash",
  components: {},
  mixins: [uploaderMixin],
  data: () => ({
    showToast: false
  }),
  methods: {
    copyToClipboard() {
      this.showToast = true
      navigator.clipboard.writeText(this.sha.join(','))
      setTimeout(() => this.showToast = false , 1000)
    },
    reset() {
      this.files = []
      this.sha = []
    }
  }
}
</script>

<style>
.custom_drag_drop_container {
  border: 2px dotted !important;
  background-color: lightgray;
}

.custom_drop_area {
  min-height: 200px;
  cursor: pointer;
}
</style>