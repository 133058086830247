const messages = {
	it: {
		"lbl_elaborazione_terminata" : "Impronta generata correttamente",
		"name": "Nome File",
		"size": "Dimensione",
		"last_modified": "Ultima Modifica",
		"lbl_calcola": "Calcola Impronta File (SHA-256)",
		"lbl_verifica": "Verifica Impronta File (SHA-256)",
		"lbl_drag_and_drop_area": "Trascina qui i file",
		"lbl_nota_title": "PRIVACY E SICUREZZA",
		"lbl_nota": "Per garantire la riservatezza dei vostri dati, durante il calcolo dell'impronta file, nessun dato verrà inviato sul server remoto. L'elaborazione avverrà direttamente sul vostro dispositivo, tramite una routine JavaScript.",
		"lbl_nota_2_tile": "Informazioni Tecniche",
		"lbl_nota_2": "Algoritmo SHA-256, Libreria asmcrypto",
		"lbl_inserisci_codici_hash": "Inserisci SHA-256",
		"hash": "SHA-256",
		"copy": "Copia",
		"reset": "Reset",
		"copied": "Sha copiati nella clipboard",
		"lbl_element_ok": "Ok",
		"check": "Verifica",
		"lbl_element_error": "Errato",
		"lbl_errori": "Sono presenti degli errori",
		"lbl_inserisci_hash": "Inserisci almeno un hash",
		"lbl_check_success": "Tutte le impronte sono verificate",
		"lbl_missing_sha": "Il numero di impronte non è uguale al numero di file caricati",
		"textarea_hint": "Inserisci solo codici SHA-256 separati da una virgola"
	}
}

export default messages